import myTransform from '@/libs_sz/utils/transform'
export default {
  data() {
    return {
      factoryList: [],
      workshopList: [],
      workshoplist: [],
      warehouseList: [],
      warehouselist: [],
      warehouseAreaList: [],
      warehouseArealist: [],
      factoryPositionList: [],
      goodsPositionList: [],
      moveGoodsPositionList: [],
      fixedGoodsPositionList: [],
      containerCodeList: [],
      containerTypeList: [],
      fixedContainerTypeList: [],
      turnoverContainerTypeList: [],
      fixedContainerCodeList: [],
      materialCodeList: [],
      materialList: [],
      turnoverContainerCodeList: [],
      moveContainerCodeList: []
    }
  },
  activated() {
    // this.initSelectList && this.initSelectList()
  },
  methods: {
    /**
     * 获取工厂列表
     */
    async getFactoryList(params = {}) {
      this.getSelectOptions('getFactoryDictList', params).then(
        (list) => (this.factoryList = list)
      )
    },
    /**
     * 获取车间列表
     */
    getWorkshopList(params) {
      this.getSelectOptions('getWorkshopDictList', params).then((list) => {
        this.workshopList = list
        if (!params) this.workshoplist = list
      })
    },
    /**
     * 获取仓库列表
     */
    getWarehouseList(params) {
      this.getSelectOptions('getWarehouseDictList', params).then((list) => {
        this.warehouseList = list
        if (!params) this.warehouselist = list
      })
    },
    /**
     * 获取库区列表
     */
    getWarehouseAreaList(params) {
      this.getSelectOptions('getWarehouseAreaDictList', params).then((list) => {
        this.warehouseAreaList = list
        if (!params) this.warehouseArealist = list
      })
    },
    /**
     * 获取库位列表
     */
    getFactoryPositionList(params) {
      this.getSelectOptions('getFactoryPositionDictList', params).then(
        (list) => (this.factoryPositionList = list)
      )
    },
    /**
     * 获取所有货位编码列表
     */
    getGoodsPositionList(params) {
      this.$httpService(
        this.$apiStore.base('getGoodsPositionListByType'),
        params
      ).then(({ data }) => {
        this.goodsPositionList = data.map((item) => {
          return { label: item.code, value: item.id }
        })
      })
    },
    /**
     * 获取移动货位编码列表
     */
    getMoveGoodsPositionList(params) {
      this.$httpService(
        this.$apiStore.base('getGoodsPositionListByType'),
        params
      ).then(({ data }) => {
        this.moveGoodsPositionList = data.map((item) => {
          return { label: item.code, value: item.id }
        })
        this.$store.dispatch('moreData/addMoveGoodsPositionList', this.moveGoodsPositionList)
      })
    },
    /** 获取固定货位编码列表
     */
    getFixedGoodsPositionList(params) {
      this.$httpService(
        this.$apiStore.base('getGoodsPositionListByType'),
        params
      ).then(({ data }) => {
        this.fixedGoodsPositionList = data.map((item) => {
          return { label: item.code, value: item.id }
        })
      })
    },
    /**
     * 获取容器类型列表
     * @param {*} params
     */
    async getContainerTypeList(params = {}) {
      await this.getSelectOptions('getContainerTypeDictList', params).then(
        (list) => (this.containerTypeList = list)
      )
    },
    // 获取固定容器类型列表
    async getFixedContainerTypeList() {
      const params = {
        containerTypePatternList: ['containerPatternFixedShelf', 'containerPatternStackingShelves']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerType'), params)
      if (code) return
      this.fixedContainerTypeList = myTransform.arrToOptions(data, 'containerTypeName', 'containerTypeId')
    },
    // 获取周转容器类型
    async getTurnoverContainerTypeList() {
      const params = {
        containerTypePatternList: ['containerPatternBarrel', 'containerPatternCrate']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerType'), params)
      if (code) return
      this.turnoverContainerTypeList = myTransform.arrToOptions(data, 'containerTypeName', 'containerTypeId')
    },
    /** 获取所有容器编码列表
     * @param {*} params
     */
    getContainerCodeList(params = {}) {
      this.getSelectOptions('getContainerDictList', params).then(
        (list) => {
          this.containerCodeList = list
          this.$store.dispatch('moreData/addContainerCodeList', list)
        }
      )
    },
    // 获取固定容器编码
    async getFixedContainerCodeList() {
      const params = {
        containerTypePatterns: ['containerPatternFixedShelf', 'containerPatternStackingShelves']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerCode'), params)
      if (code) return
      this.fixedContainerCodeList = myTransform.arrToOptions(data, 'containerArchivesCode', 'id')
    },
    // 获取周转容器编码
    async getTurnoverContainerCodeList() {
      const params = {
        containerTypePatterns: ['containerPatternCrate', 'containerPatternBarrel']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerCode'), params)
      if (code) return
      this.turnoverContainerCodeList = myTransform.arrToOptions(data, 'containerArchivesCode', 'id')
    },
    // 获取移动容器编码
    async getMoveContainerCodeList() {
      const params = {
        containerTypePatterns: ['containerPatternShelf', 'containerPatternTray', 'containerPatternRobotComponents']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerCode'), params)
      if (code) return
      this.moveContainerCodeList = myTransform.arrToOptions(data, 'containerArchivesCode', 'id')
      this.$store.dispatch('moreData/addMoveContainerCodeList', this.moveContainerCodeList)
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialOption')
      )
      if (code) return
      this.materialCodeList = data.map((item) => {
        return { label: item.materialCode, value: item.id }
      })
      this.materialList = data.map((item) => {
        return { label: item.materialName, value: item.id }
      })
    },
    /**
     * 获取数据列表
     * @param {*} apiName 接口名
     * @param {*} params 请求参数
     * @returns 选项列表
     */
    getSelectOptions(apiName, params) {
      const that = this
      return new Promise((resolve, reject) => {
        this.$httpService(this.$apiStore.base(apiName), params)
          .then(({ data, code }) => {
            if (code !== 0) {
              reject()
              return
            }

            const recordList = data || []

            // todo: 这里需要性能优化
            const ckListItem = recordList[0]
            if (ckListItem) {
              let list = []
              switch (true) {
                case !!ckListItem.containerTypeCode:
                  list = recordList.map((item) => {
                    return {
                      label: that.$t(item.containerTypeName),
                      value: item.id,
                      containerTypeCode: item.containerTypeCode,
                      containerTypePattern: item.containerTypePattern,
                      containerTypePatternType: item.containerTypePatternType
                    }
                  })
                  break

                case !!ckListItem.containerArchivesCode:
                  list = recordList.map((item) => {
                    return {
                      label: item.containerArchivesCode,
                      value: item.id
                    }
                  })
                  break

                case !!ckListItem.factoryPositionCode:
                  list = recordList.map((item) => {
                    return {
                      label: item.factoryPositionName,
                      value: item.id,
                      factoryPositionCode: item.factoryPositionCode,
                      factoryPositionType: item.factoryPositionType,
                      freezeFlag: item.freezeFlag
                    }
                  })
                  this.$store.dispatch('moreData/addFpCodeList', list)
                  break

                default:
                  list = recordList.map((item) => {
                    return {
                      label: this.$t(item.name) || item.code,
                      value: item.id
                    }
                  })
                  break
              }

              resolve(list)
            } else {
              resolve([])
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /**
   * 所属工厂 change
   * 工厂-车间
   * 工厂-仓库
   */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.workshopId = ''
      formData.warehouseId = ''
      formData.warehouseAreaId = ''
      const params = formData.factoryId ? { factoryId: formData.factoryId } : {}
      this.getWorkshopList(params)
      this.getWarehouseList(params)
      this.getWarehouseAreaList(params)
    },
    /**
     * 所属车间 change
     * 车间-库区
     */
    handleWorkshopChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.warehouseAreaId = ''
      const params = formData.workshopId ? { workshopId: formData.workshopId } : {}
      this.getWarehouseAreaList(params)
    },
    /**
     * 所属仓库 change
     * 仓库-库区
     */
    handleWarehouseChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myQueryView.$refs.myForm.$refs.moreQueryData.formModel
      this.$set(formData, formItem.name, val)
      formData.warehouseAreaId = ''
      const params = formData.warehouseId ? { warehouseId: formData.warehouseId } : {}
      this.getWarehouseAreaList(params)
    }
  }
}
