<template>
  <span>
    <el-tooltip placement="top-start" effect="light">
      <div slot="content">
        <span class="sp">{{ $t("lang.gles.material.batchPropertyDetail") }}</span>
        <el-table
          v-if="tableItemData.length"
          size="mini"
          :data="tableData"
          style="width: 100%"
          border
          stripe
          max-height="250"
        >
          <el-table-column
            v-for="item in tableItemData"
            :key="item.prop"
            width="160"
            :prop="item.prop"
            :label="$t('lang.gles.base.'+item.prop)"
          />
        </el-table>
        <div v-else class="empty">
          {{ $t("libsSz.key45") }}
        </div>
      </div>
      <span class="tip">{{ $t('lang.gles.common.view') }}</span>
    </el-tooltip>
  </span>
</template>

<script>
import dayjs from 'dayjs'
import { filterParams } from '@/utils/utils'
export default {
  props: {
    gridData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    tableData() {
      // console.log(this.gridData, 'gridData')
      if (this.gridData.batchProperty) {
        const data = JSON.parse(this.gridData.batchProperty)
        data.proDate ? data.proDate = this.formatTime(data.proDate) : ''
        data.expDate ? data.expDate = this.formatTime(data.expDate) : ''
        return [data]
      }
      return []
    },
    // 动态展示表头
    tableItemData() {
      if (this.gridData.batchProperty) {
        const data = filterParams(JSON.parse(this.gridData.batchProperty))
        // console.log(data, 'data')
        // const data = JSON.parse(this.gridData.batchProperty)
        return Object.keys(data).length ? Object.keys(data).map(item => { return { prop: item } }) : []
      }
      return []
    }
  },
  methods: {
    formatTime(data) {
      const time = typeof data === 'string' ? data.replace(/( \d+\:\d+\:\d+)\:(\d+)/, '$1.$2') : data
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style scoped lang="scss">
.sp {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 10px;
}
.tip {
  text-align: center;
  cursor: pointer;
  color:#409EFF;
}
.empty{
  color: rgba(0, 0, 0, 0.45);
  padding:10px 40px;
}
</style>
